<template>
  <div>
    <!-- 顶部导航 -->
    <Header />
    <!-- 内容 -->
    <router-view></router-view>
    <!-- 底部 -->
    <Footer  />
    <!-- 回到顶部  -->
    <div>
      <a-back-top />
      <strong style="color: rgba(64, 64, 64, 0.6)"></strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  components: {
    Header: () => import('./Header.vue'),
    Footer: () => import('./Footer.vue')
  },
  computed: {
    isLargeScreen () {
      return this.screenWidth >= 992
    }
  },
  data () {
    return {
      screenWidth: document.body.clientWidth // 屏幕尺寸
    }
  },
  beforeMount () {
    this.$store.commit('changeScreenSize', this.screenWidth >= 992)
    window.addEventListener('resize', this.resizeScreen)
  },
  methods: {
    // 屏幕尺寸变化
    resizeScreen () {
      this.screenWidth = document.body.clientWidth
      this.$store.commit('changeScreenSize', this.screenWidth >= 992)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeScreen)
  }
}
</script>

<style lang="scss" scoped>
@import url('../../assets/style/officialBase.scss');
</style>
